/*
 * assets/main.scss
 * the dashed lines above are required
 */

$background-color: #ffffff; /* a dark, off-black for the bg */
/* $text-color: #e6e6e6;  an off-white for the text */
$grey-color: #616161; /* Change grey text */
$grey-color-light: #ffffff; /* for the border-top separators */
$grey-color-dark: #adadad; /* such as the site title in the header, visited */
$brand-color: #1A63C1; /* link color */
@import "minima";
